input.assistant-select-box-input {
    color: transparent !important;
    text-shadow: 0 0 0 #494949;
    cursor: default;
}


input.assistant-select-box-input:focus {
    outline: none;
    user-select: none;
}